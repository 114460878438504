import React from 'react'
import classNames from 'classnames'

import ColourPalette from 'src/components/ColourPalette'

import styles from './styles.module.scss'

const colourPaletteData = [
    {
        title: 'Primary color',
        text:
            'The main constant color of the Kalaton brand is dark purple. It is not too bright and serves as a background  and basis for minor colors.',
        colorParam: {
            Hex: '#261d3f',
            RGB: '38/29/63',
            CMYK: '80/75/35/35',
            Pantone: '2695 C',
        },
    },
    {
        title: 'Secondary color',
        text:
            'Background colors depend on the type of activity. They are very bright and noticeable. They need to be used in a minimal amount.',
        logo: '',
        colorParam: {
            Hex: '#f19876',
            RGB: '241/152/118',
            CMYK: '5/40/40/0',
            Pantone: '1625 C',
        },
    },
    {
        title: 'Neutral colors',
        text:
            'The neutral color palette consists of cool grays and should only be used primarily as background color and for typography.',
        colorSet: [
            {
                Hex: '#e5e5e5',
                RGB: '230/231/232',
                CMYK: '0/0/0/10',
                Pantone: 'Cool gray 1C',
            },
            {
                Hex: '#c4c4c4',
                RGB: '196/196/196',
                CMYK: '0/0/0/23',
                Pantone: 'Cool gray 3C',
            },
            {
                Hex: '#999999',
                RGB: '167/169/172',
                CMYK: '0/0/0/40',
                Pantone: 'Cool gray 6C',
            },
        ],
    },
    {
        title: 'Color usage guideline',
        text:
            'Colors should be used in the proper ratio. The primary color is the most prominent, followed by secondary colors.',
        colorSetPercent: [
            {
                type: 'primary',
                Hex: '#261d3f',
                Percent: 60,
            },
            {
                type: 'secondary',
                Hex: '#f19876',
                Percent: 30,
            },
            {
                type: 'neutral',
                Hex: '#e5e5e5',
                Percent: 10,
            },
        ],
    },
]

const classNamesColourPalette = classNames('container', styles.colourPaletteBrandingSystems)
const classNamesTitle = classNames('calaton-systems-branding__part-title', styles.title)

const ColourPaletteBrandingStudio = () => {
    return (
        <section className={classNamesColourPalette} id="colour-palette">
            <span className="calaton-systems-branding__part">1.2</span>
            <h2 className={classNamesTitle}>Color Palette</h2>
            <ColourPalette data={colourPaletteData} />
        </section>
    )
}

export default ColourPaletteBrandingStudio
