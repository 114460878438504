import React from 'react'
import classNames from 'classnames'

import SvgSprite from 'src/components/SvgSprite'
import Picture from 'src/components/Picture'
import ideaMobile from 'src/images/optimized/png/CalatonStudioBrandingWeb/ideaMobile.png'
import ideaMobileRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/ideaMobile@2x.png'
import ideaDescktop from 'src/images/optimized/png/CalatonStudioBrandingWeb/ideaDesktop@2x.png'
import ideaDescktopRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/ideaDesktop@2x.png'
import perspectiveMobile from 'src/images/optimized/png/CalatonStudioBrandingWeb/perspectiveMobile.png'
import perspectiveMobileRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/perspectiveMobile@2x.png'
import perspectiveDesktop from 'src/images/optimized/png/CalatonStudioBrandingWeb/perspectiveDesktop.png'
import perspectiveDesktopRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/perspectiveDesktop@2x.png'

import styles from './styles.module.scss'

const ideaImages = {
    source: [
        {
            srcSet: ideaMobile,
            retina: ideaMobileRetina,
            media: '(max-width: 990px)',
        },
        {
            srcSet: ideaDescktop,
            retina: ideaDescktopRetina,
            media: '(min-width: 990px)',
        },
    ],
    src: ideaDescktop,
    retina: ideaDescktopRetina,
    alt: 'calaton studio idea branding',
}

const perspectiveImages = {
    source: [
        {
            srcSet: perspectiveMobile,
            retina: perspectiveMobileRetina,
            media: '(max-width: 990px)',
        },
        {
            srcSet: perspectiveDesktop,
            retina: perspectiveDesktopRetina,
            media: '(min-width: 990px)',
        },
    ],
    src: perspectiveDesktop,
    retina: perspectiveDesktopRetina,
    alt: 'calaton studio perspective branding',
}

const classNamesBrandSystem = classNames('container', styles.brandSystem)
const classNamesTitle = classNames('calaton-systems-branding__part-title', styles.title)

const BrandSystem = () => {
    return (
        <section className={classNamesBrandSystem} id="brand-system">
            <span className="calaton-systems-branding__part">1.1</span>
            <h2 className={classNamesTitle}>Brand System</h2>
            <p className={styles.brandSystemText}>
                Calaton systems is part of the Calaton Inc. group, which also includes Calaton media
                and Calaton studio. The logo consists of a sign and a text block. Calaton text block
                is unchanged for all logos.
            </p>
            <div className={styles.logoContainer}>
                <SvgSprite name="logoBigCalatonSystem" className={styles.svgLogo} />
            </div>
            <div className={styles.ideaAndPerspectiveContainer}>
                <div>
                    <h3 className={styles.subsectionsTitle}>Idea</h3>
                    <p>
                        In the future, Calaton Inc. several more areas of activity may appear. Or,
                        conversely, it will abandon existing ones. The sign allows you to solve
                        these situations with the help of minimal changes in appearance.
                    </p>
                    <Picture className={styles.pictureIdea} pic={ideaImages} />
                </div>
                <div>
                    <h3 className={styles.subsectionsTitle}>Perspective</h3>
                    <p>
                        By simply increasing or decreasing the number of ring segments. These
                        changes do not reduce the recognition of the logo.
                    </p>
                    <Picture className={styles.picturePerspective} pic={perspectiveImages} />
                </div>
            </div>
        </section>
    )
}

export default BrandSystem
