import React from 'react'
import classNames from 'classnames'

import NavBrandIdentity from 'src/components/NavBrandIdentity'
import logo from 'src/images/optimized/svg/logoBigCalatonSystems.svg'

import styles from './styles.module.scss'

const classNamesIntor = classNames('container', styles.intro)
const classNamesTitle = classNames('container', styles.title)

const BrandIdentity = () => {
    return (
        <section className={styles.brandIdentity} id="brand-identity">
            <h1 className={classNamesTitle}>
                Calaton Systems <span className={styles.break}>Brand Identity</span>
            </h1>
            <div className={classNamesIntor}>
                <div className={styles.logo}>
                    <img src={logo}></img>
                </div>
                <h3 className={styles.subtitle}>To make technology real</h3>
                <p className={styles.textFirst}>
                    The task was to create an identity for an IT company from scratch, with only the
                    name Calaton available.
                    <span>
                        The preference was given to a minimalistic modern style with a variable
                        logo. In the process of work, we were able to find a balance between the
                        seriousness and manufacturability of the IT sphere and a modern bright and
                        playful creative accent.
                    </span>
                </p>
                <div className={styles.navBrandIdentityContainer}>
                    <NavBrandIdentity className={styles.navBrandIdentityCalatonSystems} />
                </div>
                <p className={styles.textSecond}>
                    The preference was given to a minimalistic modern style with a variable logo. In
                    the process of work, we were able to find a balance between the seriousness and
                    manufacturability of the IT sphere and a modern bright and playful creative
                    accent.
                </p>
            </div>
        </section>
    )
}

export default BrandIdentity
