import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const classNameWebSolutionsContainer = classNames('container', styles.container)
const classNamesTitle = classNames('calaton-systems-branding__part-title', styles.title)
const classNamesPart = classNames('calaton-systems-branding__part', styles.part)

const WebSolutions = () => {
    return (
        <section className={styles.webSolutions} id="web-solution">
            <div className={classNameWebSolutionsContainer}>
                <span className={classNamesPart}>2.1</span>
                <h2 className={classNamesTitle}>Web solutions</h2>
                <p className={styles.text}>
                    We improve and optimize business processes by building efficient, reliable and
                    scalable technology solutions.
                </p>
            </div>
        </section>
    )
}

export default WebSolutions
