import React from 'react'
import classNames from 'classnames'

import Picture from 'src/components/Picture'
import graphicMobile from 'src/images/optimized/png/CalatonSystemsBrandingWeb/graphicsMobile.png'
import graphicMobileRetina from 'src/images/optimized/png/CalatonSystemsBrandingWeb/graphicsMobile@2x.png'
import graphicDesktop from 'src/images/optimized/png/CalatonSystemsBrandingWeb/graphicsDesktop.png'
import graphicDesktopRetina from 'src/images/optimized/png/CalatonSystemsBrandingWeb/graphicsDesktop@2x.png'

import styles from './styles.module.scss'

const graphics = {
    source: [
        {
            srcSet: graphicMobile,
            retina: graphicMobileRetina,
            media: '(max-width: 990px)',
        },
        {
            srcSet: graphicDesktop,
            retina: graphicDesktopRetina,
            media: '(min-width: 990px)',
        },
    ],
    src: graphicDesktop,
    retina: graphicDesktopRetina,
    alt: 'calaton studio graphics branding',
}

const classNameTypographyContainer = classNames('container', styles.container)
const classNamesTitle = classNames('calaton-systems-branding__part-title', styles.title)
const classNamesPart = classNames('calaton-systems-branding__part', styles.part)

const Graphics = () => {
    return (
        <section className={styles.graphics} id="graphic">
            <div className={classNameTypographyContainer}>
                <div className={styles.header}>
                    <span className={classNamesPart}>1.4</span>
                    <h2 className={classNamesTitle}>Graphics</h2>
                    <p className={styles.text}>
                        To enrich the graphic language of the brand and make it visually
                        recognizable and attractive, you need to use a unique way to use images. The
                        most logical will be to use logo elements.
                    </p>
                </div>
                <div className={styles.body}>
                    <div className={styles.image}>
                        <Picture className={styles.pictureGraphics} pic={graphics} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Graphics
