import React from 'react'

import BaseContainer from 'src/components/BaseContainer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import CalatonSystemsBrandingWebCase from 'src/components/CalatonSystemsBrandingWebCase'
import Meta from '../../components/Meta';

const metaData = {
    title: 'Calaton Systems Brand Identity',
    description: 'Calaton Studio we have many cases with completed works',
    keywords: 'Calaton Studio, cases',
}

const CalatonSystemBranding = () => {
    return (
        <BaseContainer>
	        <Meta content={metaData} />
            <Header />
            <CalatonSystemsBrandingWebCase />
            <Footer />
        </BaseContainer>
    )
}

export default CalatonSystemBranding
